import React, { useState, useEffect } from 'react';
import axios from 'axios';
import * as XLSX from 'xlsx';
import $ from 'jquery';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'datatables.net-bs4/css/dataTables.bootstrap4.min.css';
import 'datatables.net';
import 'datatables.net-bs4';
import 'bootstrap/dist/js/bootstrap.bundle.min';

const WhatsAppMessageSender = () => {
  const [progressCompleted, setProgressCompleted] = useState(false);

  const [progress, setProgress] = useState({ completedRecipients: 0, totalRecipients: 0 });
  let dataTableInstance; // Variable to store DataTable instance

  useEffect(() => {
    document.getElementById('fileInput').addEventListener('change', handleFile);

    // Load values from localStorage on component mount
    document.getElementById('token').value = localStorage.getItem('token') || '';
    document.getElementById('phoneId').value = localStorage.getItem('phoneId') || '';
    document.getElementById('wabaId').value = localStorage.getItem('wabaId') || '';

    // Initialize DataTable after the DOM is fully loaded
    $(document).ready(() => {
      // Check if DataTable is already initialized
      if (!$.fn.DataTable.isDataTable('#dataTable')) {
        dataTableInstance = new $.fn.dataTable('#dataTable', {
          order: [], // Disable initial sorting
        });
      }
    });
  }, []);

  const handleFile = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = function (e) {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];

        const tableData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
        const headers = tableData[0];
        const rows = tableData.slice(1);

        renderDataTable(headers, rows);
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const renderDataTable = (headers, rows) => {
    $(document).ready(function () {
      const nonEmptyRows = rows.filter(row => row.some(cell => cell !== null && cell !== undefined && cell !== ''));

      // Destroy the existing DataTable instance if it exists
      if (dataTableInstance) {
        dataTableInstance.destroy();
      }

      dataTableInstance = new $.fn.dataTable('#dataTable', {
        data: nonEmptyRows,
        columns: headers.map((header) => ({ title: header })),
        order: [], // Disable initial sorting
        columnDefs: [{
          targets: '_all',
          defaultContent: ''
        }]
      });
    });
  };

  const showTemplates = () => {
    const token = document.getElementById('token').value;
    const wabaId = document.getElementById('wabaId').value;

    // Save values to localStorage
    localStorage.setItem('token', token);
    localStorage.setItem('phoneId', document.getElementById('phoneId').value);
    localStorage.setItem('wabaId', wabaId);

    // Validation
    if (!token || !wabaId) {
      alert('Please fill in all required fields (Access Token and WABA ID)');
      return;
    }

    axios
      .get(`https://graph.facebook.com/v16.0/${wabaId}/message_templates`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const templates = response.data.data;

        if (templates.length === 0) {
          alert('No templates found.');
          return;
        }

        const templateSelect = document.getElementById('template');
        templateSelect.innerHTML = templates.map((template) => {
          return `<option value="${template.name}_${template.language}">${template.name} (${template.language})</option>`;
        }).join('');
      })
      .catch((error) => {
        console.error(error);
      });
  };


  const sendMessage = () => {
    const token = document.getElementById('token').value;
    const phoneId = document.getElementById('phoneId').value;
    const wabaId = document.getElementById('wabaId').value;
    const templateValue = document.getElementById('template').value;

    // Validation
    if (!token || !phoneId || !wabaId) {
      alert('Please fill in all required fields (Access Token, Phone ID, and WABA ID)');
      return;
    }

    // Split the templateValue into name and language
    const [templateName, languageCode] = templateValue.split('_');

    const table = new $.fn.dataTable('#dataTable');
    const recipientWaIds = table.column(1).data().toArray();
    const totalRecipients = recipientWaIds.length;

    setProgress({ completedRecipients: 0, totalRecipients });

    recipientWaIds.forEach((recipientWaId, index) => {
      const apiUrl = `https://graph.facebook.com/v16.0/${phoneId}/messages`;
      const payload = {
        messaging_product: 'whatsapp',
        to: recipientWaId,
        type: 'template',
        template: {
          name: templateName,
          language: {
            code: languageCode,
          },
        },
      };

      axios
        .post(apiUrl, payload, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          console.log(response.data);
          updateProgress(index + 1);
        })
        .catch((error) => {
          console.error(error);
          updateProgress(index + 1);
        });
    });
  };

  const updateProgress = (completedRecipients) => {
    setProgress((prevProgress) => ({
      ...prevProgress,
      completedRecipients,
    }));
  
    const progressValue = progress.totalRecipients === 0 ? 100 : (completedRecipients / progress.totalRecipients) * 100;
  
    // Ensure progressValue is not greater than 100%
    const clampedProgressValue = Math.min(100, progressValue);
  
    // Update the progress bar and text
    if (document.getElementById('progressBar')) {
      document.getElementById('progressBar').style.width = `${clampedProgressValue}%`;
    }
  
    if (document.getElementById('progressText')) {
      document.getElementById('progressText').innerText = `${clampedProgressValue.toFixed(2)}%`;
    }
  
    if (completedRecipients === progress.totalRecipients) {
      setProgressCompleted(true);
      // Additional actions upon completion, e.g., hide progress bar
      // $('#progressModal').modal('hide');
    }
  };


  return (
    <div className="container mt-4">
      <div className="row">
        <div className="col-md-6">
          <div className="form-group">
            <label htmlFor="token">Access Token:</label>
            <input type="text" id="token" className="form-control" defaultValue={localStorage.getItem('token')} />

            <label htmlFor="phoneId">Phone ID:</label>
            <input type="text" id="phoneId" className="form-control" defaultValue={localStorage.getItem('phoneId')} />

            <label htmlFor="wabaId">WABA ID:</label>
            <input type="text" id="wabaId" className="form-control" defaultValue={localStorage.getItem('wabaId')} />

            <label htmlFor="template">Select Template:</label>
            <select id="template" className="form-control">
              {/* Options will be populated dynamically using JavaScript */}
            </select>

            <button onClick={showTemplates} className="btn btn-primary mt-2">
              Show Templates
            </button>
            <button onClick={sendMessage} className="btn btn-success mt-2">
              Send Messages
            </button>
          </div>

          <input type="file" id="fileInput" className="mt-3" />
        </div>

        <div className="col-md-6">
          <table id="dataTable" className="table">
            <thead>
              {/* Table header will be populated dynamically */}
            </thead>
            <tbody>
              {/* Table body will be populated dynamically */}
            </tbody>
          </table>

          <div className="progress mt-3">
            {!progressCompleted && (
              <div className="progress">
                <div id="progressBar" className="progress-bar" role="progressbar" style={{ width: '0%' }} aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
              </div>
            )}
            <p id="progressText" className="mt-1">0%</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhatsAppMessageSender;
